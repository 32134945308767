<template>

  <div v-if="policyData" class="pb text-style-info">

    <div class="html_into" v-html="policyData.body"></div>

  </div>

</template>

<script setup lang="ts">

const { $api } = useNuxtApp();

const { data: policyData } = await useLazyAsyncData('policyData', async () => await $api.getStaticPage('policy'))

useHead(useNuxtApp().$head.getStaticPage());
 
</script>

<style scoped>

.text-style-info{
  text-indent: 2rem;
}

.html_into :v-deep(.de_im){
  height: 14px;
  vertical-align: middle;
}
 
</style>